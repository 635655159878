const fakeUsers = [
  {
    email: 'admin@admin.com',
    password: 'admin',
    fullName: 'Roman Johanson',
    avatar: `${process.env.PUBLIC_URL}/img/fake_avatar.png`,
  },
];

export default fakeUsers;
